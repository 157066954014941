import React from 'react'
// import CercleShape from "../../assets/images/services-left-image/cercle-shape.png"
// import MainStatic from "../../assets/images/why-choose-us-image/main-static.png"

import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
{
    strapiWhyChooseUs {
        titleText
        manImage {
            id
            name
            url
        }
        structureImage {
            id
            name
            url
        }
        options {
            id
            flatIcon
            title
            description
        }
        description
    }
}
`

const WhyChoose = () => {

    // const data = useStaticQuery(query)

    // const {
    //     strapiWhyChooseUs: {
    //         titleText, 
    //         description,
    //         manImage: {
    //             childImageSharp: manStand
    //         },
    //         structureImage: {
    //             childImageSharp: database
    //         },
    //         options
    //     }
    // } = data

    // console.log(options)


    return (
        <div className="why-choose-us ptb-80 pt-0">
            <div className="container">
                <div className="row align-items-center"></div>
            </div>
        </div>
    );
}

export default WhyChoose;
